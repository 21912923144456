<script lang="ts" setup>
import { BaseModalButton } from '#components';

const MODAL_KEY = 'main-menu';

const { t } = useI18n();
const commonStore = useCommonStore();
const modalStore = useModalStore();
const productCartStore = useProductCartStore();
const userStore = useUserStore();
const router = useRouter();
const route = useRoute();

const isBackNavigationButton = computed(() =>
  /^\/(auth|order)/.test(route.path),
);
const isBackNavigationButtonDark = computed(() => /^\/order/.test(route.path));

watch(
  () => route.fullPath,
  () => {
    modalStore.closeModal(MODAL_KEY);
  },
);

const onGlobalClick = () => {
  if (modalStore.modalKeys[MODAL_KEY]) {
    modalStore.closeModal(MODAL_KEY);
  }
};

const onOpenProductCart = () => {
  modalStore.closeModal(MODAL_KEY);
  productCartStore.open();
};

const onClickMenuButton = () => {
  const back = router.options.history.state.back;

  const navigateBack = () => {
    if (!back || route.path === back) router.push('/');
    else router.back();
  };

  if (modalStore.modalKeys[MODAL_KEY]) modalStore.closeModal(MODAL_KEY);
  else if (/^\/auth/.test(route.path)) {
    if (back && back !== '/profile') navigateBack();
    else router.push('/');
  } else if (/^\/order\/successful/.test(route.path)) router.push('/catalog');
  else if (/^\/order/.test(route.path)) navigateBack();
  else modalStore.openModal(MODAL_KEY);
};

onMounted(() => {
  window.addEventListener('click', onGlobalClick);
});

onBeforeUnmount(() => {
  modalStore.closeModal(MODAL_KEY);
  window.removeEventListener('click', onGlobalClick);
});
</script>

<template>
  <header class="mainMenu__header">
    <BaseUserButton
      v-if="!isBackNavigationButton"
      @click.stop="userStore.toProfile"
      :dark="isBackNavigationButtonDark || !!modalStore.modalKeys[MODAL_KEY]"
      :count="productCartStore.totalCount"
      :aria-label="t('action.open_user')"
      data-test-id="user-toggle"
    />
    <Transition name="fade">
      <BaseCartButton
        v-if="!isBackNavigationButton"
        @click.stop="onOpenProductCart"
        :dark="isBackNavigationButtonDark || !!modalStore.modalKeys[MODAL_KEY]"
        :count="productCartStore.totalCount"
        :aria-label="t('action.open_cart')"
        data-test-id="product-cart-toggle"
      />
    </Transition>
    <BaseModalButton
      @click.stop="onClickMenuButton"
      :burger="!modalStore.modalKeys[MODAL_KEY] && !isBackNavigationButton"
      :dark="isBackNavigationButtonDark"
      :aria-label="t('action.open_menu')"
      data-test-id="navigation-menu-toggle"
    />
  </header>

  <BaseSidebar
    @update:visible="modalStore.toggleModal(MODAL_KEY, $event)"
    :visible="!!modalStore.modalKeys[MODAL_KEY]"
    full-height
    tag="nav"
    class="mainMenu"
    data-test-id="navigation-menu"
  >
    <div class="mainMenu__content">
      <div class="mainMenu__column">
        <NuxtLink to="/catalog" class="mainMenu__catalog">
          {{ t('link.catalog') }}
        </NuxtLink>
        <NuxtLink
          v-for="item in commonStore.sections"
          :key="item.id"
          :to="{ path: '/catalog', query: { categories: item.code } }"
          class="mainMenu__catalog"
        >
          {{ item.name }}
        </NuxtLink>
      </div>
      <div class="mainMenu__column">
        <NuxtLink to="/" class="mainMenu__subLink">
          {{ t('link.main') }}
        </NuxtLink>
        <NuxtLink to="/about" class="mainMenu__subLink">
          {{ t('link.about') }}
        </NuxtLink>
        <NuxtLink to="/contacts" class="mainMenu__subLink">
          {{ t('link.contacts') }}
        </NuxtLink>
        <NuxtLink to="/faq" class="mainMenu__subLink">
          {{ t('link.faq') }}
        </NuxtLink>
        <NuxtLink to="/blog" class="mainMenu__subLink">
          {{ t('link.blog') }}
        </NuxtLink>
        <NuxtLink to="/delivery-and-payment" class="mainMenu__subLink">
          {{ t('link.delivery_and_payment') }}
        </NuxtLink>
        <div class="mainMenu__additional">
          <button @click="userStore.toProfile" class="mainMenu__subLink">
            <template v-if="userStore.authorized">
              <SvgoSimpleUser
                filled
                :font-controlled="false"
                class="mainMenu__linkIcon mainMenu__linkIcon_inverted"
              />
              {{ t('profile') }}
            </template>
            <template v-else>
              <SvgoSimpleAuth
                filled
                :font-controlled="false"
                class="mainMenu__linkIcon"
              />
              {{ t('auth') }}
            </template>
          </button>
        </div>
      </div>
    </div>
  </BaseSidebar>
</template>

<i18n>
ru:
  action:
    open_user: Открыть личный кабинет
    open_cart: Открыть корзину
    open_menu: Открыть навигационное меню
  link:
    main: Главная
    about: О бренде
    contacts: Контакты
    faq: FAQ
    delivery_and_payment: Доставка и оплата
    subscribe_product: Подписка на рефилы
    blog: Блог
    catalog: Все продукты
  auth: Войти
  profile: Личный кабинет

en:
  action:
    open_cart: Open cart
    open_menu: Open navigation menu
  link:
    main: Main
    about: About
    contacts: Contacts
    faq: FAQ
    delivery_and_payment: Delivery and payment
    subscribe-product: Subscription for Refills
    blog: Blog
    catalog: All products
  auth: Athorization
  profile: Account
</i18n>

<style lang="scss">
.mainMenu {
  width: 720px;
  max-width: 100%;
  background: #121212;

  @include mq('sm') {
    width: 100%;
  }

  &__header {
    position: fixed;
    display: flex;
    gap: 16px;
    top: 28px;
    right: 20px;
    pointer-events: none;
    z-index: 11;

    @include mq('sm') {
      gap: 8px;
      top: 10px;
      right: 5px;
    }

    & > * {
      pointer-events: auto;
    }
  }

  &__content {
    padding: 115px 60px 48px;
    display: flex;
    gap: 34px;

    @include mq('sm') {
      padding: 32px 16px;
      flex-direction: column;
      height: auto;
      gap: 52px;
    }
  }

  &__column {
    display: flex;
    flex-direction: column;
    gap: 14px;

    &:nth-child(1) {
      width: 301px;
    }

    @include mq('sm') {
      gap: 10px;

      &:nth-child(1) {
        margin-top: 34px;
        width: initial;
      }
    }
  }

  &__link,
  &__catalog {
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.2px;
    color: #ffffff;
    transition: opacity 0.3s ease;

    &:hover {
      opacity: 0.7;
    }

    @include mq('sm') {
      font-size: 30px;
      line-height: 38px;
    }
  }

  &__catalog {
    font-size: 24px;
    line-height: 32px;
  }

  &__additional {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: auto;

    @include mq('sm') {
      margin-top: 42px;
    }
  }

  &__linkIcon {
    height: 24px;
    margin-bottom: 3px;
    margin-right: 8px;

    @include mq('sm') {
      margin-bottom: 4px;
      margin-right: 12px;
    }

    &_inverted {
      filter: invert(1);
    }
  }

  &__subLink {
    display: flex;
    align-items: flex-end;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.2px;
    color: #ffffff;
    transition: opacity 0.3s ease;
    background: none;
    padding: 0;
    border: none;
    text-align: start;
    cursor: pointer;
    width: fit-content;

    &:hover {
      opacity: 0.7;
    }
  }
}
</style>
